import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";


const PanelPromo = () => {
    const [promoTypes, setPromoTypes] = useState([]);
    const [selectedPromoType, setSelectedPromoType] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(20);
    const [expirationMonths, setExpirationMonths] = useState(1);
    const [responseMessage, setResponseMessage] = useState('');

    const [currentPage, setCurrentPage] = useState(0);  // Текущая страница
    const [totalPages, setTotalPages] = useState(1);  // Общее количество страниц

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        axios.get('https://gostlink.com/api/panel/promos/promoTypes', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setPromoTypes(response.data);
                if (response.data.length > 0) {
                    setSelectedPromoType(response.data[0].type);
                }
            })
            .catch(error => {
                console.error('Error fetching promo types:', error);
            });
    }, []);

    useEffect(() => {
        fetchPromoCodes()
    }, [currentPage]);

    const handlePromoCodeChange = (e) => {
        const input = e.target;
        const selectionStart = input.selectionStart;
        const formattedCode = input.value.toUpperCase().replace(/[^A-Z0-9_-]/g, '');

        if (formattedCode !== promoCode) {
            setPromoCode(formattedCode);

            setTimeout(() => {
                input.setSelectionRange(selectionStart, selectionStart);
            }, 0);
        }
    };

    const handleGeneratePromo = () => {
        const accessToken = localStorage.getItem('accessToken');
        const expirationDate = dayjs().add(expirationMonths, 'month').format('YYYY-MM-DD');
        const requestData = {
            promoCode,
            promoType: selectedPromoType,
            isAvailable: true,
            discount: discount/100,
            expirationDate
        };

        axios.post('https://gostlink.com/api/panel/promos/generatePromo', requestData, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setResponseMessage({ message: response.data.message, success: response.data.success });
                fetchPromoCodes();
                setTimeout(() => setResponseMessage(''), 10000);
            })
            .catch(error => {
                setResponseMessage({ message: error.response?.data?.message || 'Error generating promo code.', success: false });
                setTimeout(() => setResponseMessage(''), 10000);
                console.error('Error generating promo code:', error);
            });
    };




    const [promoCodes, setPromoCodes] = useState([]);
    const fetchPromoCodes = () => {
        const accessToken = localStorage.getItem('accessToken');
        axios.get(`https://gostlink.com/api/panel/promos/availablePromos?page=${currentPage}&size=5&sortBy=id&sortDirection=DESC`, {
            headers: {
                'Authorization': `Bearer ${accessToken}` // Добавляем токен в заголовок
            }
        })
            .then(response => {
                setPromoCodes(response.data.content);
                setTotalPages(response.data.page.totalPages); // Обновляем количество страниц
            })
            .catch(error => {
                console.error('Error fetching promo codes:', error);
            });
    }


    // Переход на предыдущую страницу
    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Переход на следующую страницу
    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };



    return (
        <div className="panel-container">
            <div className="panel-block">
                <span><strong>Мир промокодов</strong></span><br/>
            </div>
            <div className="panel-block">
                <p className='panel-block-title'>Генератор промокодов</p>
                {promoTypes.length > 0 ? (
                    <div className='panel-section'>
                        <div style={{textAlign: 'left'}}>
                            <label>Тип: </label>
                            <select value={selectedPromoType}
                                    onChange={(e) => setSelectedPromoType(e.target.value)}
                            >
                                {promoTypes.map((type, index) => (
                                    <option key={index} value={type.type}>{type.name}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{textAlign: 'left'}}>
                            <label>Промокод: </label>
                            <input type="text"
                                   value={promoCode}
                                   onChange={handlePromoCodeChange}
                                   placeholder="Введите промокод"
                            />
                        </div>
                        <div style={{textAlign: 'left'}}>
                            <label>Скидка: </label>
                            <input type="number"
                                   min="20"
                                   max="50"
                                   step="5"
                                   value={discount}
                                   onChange={(e) => setDiscount(Number(e.target.value))}
                            />
                        </div>
                        <div style={{textAlign: 'left'}}>
                            <label>Срок действия (мес): </label>
                            <input type="number"
                                   min="1"
                                   max="12"
                                   value={expirationMonths}
                                   onChange={(e) => setExpirationMonths(Number(e.target.value))}
                            />
                        </div>
                        <button className="panel-button"
                                onClick={handleGeneratePromo}>
                            Активировать промокод
                        </button>
                        {responseMessage &&
                            <p style={{ color: responseMessage.success ? 'green' : 'red' }}>
                                {responseMessage.message}
                            </p>
                        }
                    </div>
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
            <div className="panel-block">
                <p className='panel-block-title'>Список активных промокодов</p>
                {promoCodes.length > 0 ? (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex', width: '100%', marginBottom: '10px'}}>
                            <input
                                type="text"
                                inputMode="text"
                                placeholder="Введите промокод..."
                                style={{width: '100%', margin: '0 5px 0 0'}}
                            />
                            <button className="panel-button" style={{margin: '0 0 0 5px'}}>Поиск</button>
                        </div>
                        {promoCodes.map((promo) => (
                        <div className="panel-section" key={promo.id}>
                            <div style={{ textAlign: 'left' }}>
                                {/*<span><b>ID: </b> {promo.id}</span><br/>*/}
                                <span><b>Промокод: </b> {promo.promoCode}</span><br/>
                                <span><b>Тип акции: </b> {promo.promoType}</span><br/>
                                <span><b>Срок действия: </b> {promo.expirationDate}</span><br/>
                                <span><b>Скидка: </b> {promo.discount * 100}%</span><br/>
                                {/*<span><b>Доступность: </b> {promo.available ? 'Доступен' : 'Не доступен'}</span><br/>*/}
                            </div>
                            {/*<button className="panel-button">Заблокировать промокод</button>*/}
                        </div>
                        ))}

                        <div className="navigation-list" style={{ }}>
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 0}
                                style={{ opacity: currentPage === 0 ? 0.5 : 1 }}
                            >
                                <FaAngleLeft size={15} color='rgba(0, 0, 0, 0.7)' style={{marginRight: '2px'}}/>
                            </button>
                            <span>{currentPage + 1} / {totalPages}</span>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages - 1}
                                style={{ opacity: currentPage === totalPages - 1 ? 0.5 : 1 }}
                            >
                                {/*<IoCaretForwardOutline/>*/}
                                <FaAngleRight size={15} color='rgba(0, 0, 0, 0.7)' style={{marginLeft: '2px'}}/>
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>Нет активных промокодов</p>
                )}
            </div>
        </div>
    );
};

export default PanelPromo;

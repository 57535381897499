import React, {useState, useEffect, useRef} from 'react';
import '../css/App.css';
import '../languages/i18n';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from './elements/User';
import {useTranslation} from "react-i18next";
import {FaPlus} from "react-icons/fa6";
import {IoLogoAndroid} from "react-icons/io";
import {FaApple, FaDesktop, FaGhost} from "react-icons/fa";
import {PulseLoader} from "react-spinners";

function Profile() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const {t} = useTranslation();

    const email = localStorage.getItem('email');
    const uid = localStorage.getItem('uid');


    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchLoginData(requestData);
    }, []);

    useEffect(() => {
        // Редирект, если подписка не активна или отсутствует
        if (!userData.subscriptionIsActive) {
            navigate('/profile');
        }
    }, [userData, navigate]);

    const fetchLoginData = (requestData) => {
        fetch(`https://gostlink.com/api/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.text())
            .then((text) => {
                if (text) {
                    const data = JSON.parse(text);
                    if (data.success) {
                        localStorage.setItem('expirationDate', data.userInfo.expirationDate);
                        localStorage.setItem('subscriptionIsActive', data.userInfo.subscriptionIsActive);
                        setUserData(data.userInfo)
                        console.log(data)
                    } else {
                        // Handle failure
                    }
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error);
            });
    };

    function cancelSubscription() {
        if (!email) {
            console.error("Email отсутствует, запрос не отправлен.");
            return;
        }

        const body = {
            email: email,
            ...(uid && { uid: uid }) // Добавляем uid только если он есть
        };

        fetch('https://gostlink.com/api/subscriptions/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.ok) {
                    console.log("Запрос успешно отправлен");
                    navigate('/profile');
                } else {
                    console.error("Ошибка при отправке запроса");
                }
            })
            .catch(error => {
                console.error("Произошла ошибка:", error);
            });
    }








    const [progress, setProgress] = useState(0);
    const holdTimer = useRef(null);

    const handleMouseDown = () => {
        const startTime = Date.now();

        holdTimer.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const duration = 2000;

            if (elapsedTime >= duration) {
                clearInterval(holdTimer.current);
                setProgress(1000);
                // fetchDetachDevice(id, deviceName);
                cancelSubscription()
                return;
            }
            const linearProgress = (elapsedTime / duration) * 100;
            setProgress(linearProgress);
        }, 16);
    };

    const handleMouseUpOrLeave = () => {
        if (holdTimer.current) {
            clearInterval(holdTimer.current);
            holdTimer.current = null;
        }
        setProgress(0);
    };

    return (
        <div className="container-main">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />
            <User/>
            {userData.subscriptionIsActive ? (
                <>
                    <div className="device-box" onClick={() => navigate('/promo')} style={{padding: '20px', boxSizing: 'border-box'}}>
                    <span className="device-title">
                        {t('Dolor.Title')}
                    </span><br/>
                        <span className="device-subtitle">
                        {t('Dolor.Subtitle')}
                    </span>
                        {/*<button className='button_dark' onClick={cancelSubscription}>*/}
                        {/*    {t('Dolor.Button')}*/}
                        {/*</button>*/}
                    </div>
                    <div className="device-box device-box-button" onMouseLeave={handleMouseUpOrLeave}
                         style={{position: 'relative', overflow: 'hidden', userSelect: 'none'}}
                         onMouseDown={() => handleMouseDown()}
                         onMouseUp={handleMouseUpOrLeave}
                         onTouchStart={() => handleMouseDown()}
                         onTouchEnd={handleMouseUpOrLeave}>
                        <div className="progress-bar"
                             style={{
                                 width: `${progress}%`,
                                 height: '100%',
                                 backgroundColor: 'rgba(27, 39, 53, 0.3)',
                                 position: 'absolute',
                                 top: 0,
                                 left: 0,
                                 zIndex: 0,
                                 transition: 'width 0.04s linear'
                             }}></div>
                        <span className="device-subtitle">{t('Dolor.Button')}</span>
                        <span className="block-progress-signature">{t('Dolor.Signature-button')}</span>
                    </div>
                </>
            ) : (
                <div className="loading-spinner">
                    <PulseLoader color="#FFF" loading={true} size={10} />
                </div>
            )}
        </div>
    );
}

export default Profile;

// analytics.js

import ReactGA from "react-ga4";

export const addGoogleAnalytics = (action, category, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};

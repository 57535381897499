
import React, {useEffect, useState} from 'react';
import '../languages/i18n';
import {useNavigate} from "react-router-dom";
import './PanelStyle.css';
import {toast} from "react-toastify";


function PanelUser() {
    const navigate = useNavigate();
    const [UserData, setUserData] = useState(null);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = () => {
        const accessToken = localStorage.getItem('accessToken');
        const url = `https://gostlink.com/api/panel/users/getUserById?id=${localStorage.getItem('selected_person')}`;

        console.log(url);
        console.log(`Bearer ${accessToken}`);

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                console.log(response.status);
                console.log(response);
                if (!response.ok) {
                    return response.text().then(text => {
                        if (response.status === 401) {
                            navigate('/panel-voodoo');
                        }
                        console.log(text);
                        throw new Error(`Request failed with status ${response.status}`);
                    });
                }
                return response.text();
            })
            .then(text => {
                console.log(text);
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                setUserData(data || []);
            })
            .catch(error => {
                // console.log(error);
                console.error('Festch error:', error);
            });
    };


    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }


    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <p className='panel-block-title'>Информация о пользователе</p>
                    {UserData ? (
                        <>
                            <div style={{ textAlign: 'left' }}>
                                <div className='panel-section'>
                                    <span><b>ID:</b> </span><span>{UserData.id}</span><br/>
                                    <span><b>Email:</b> </span><span>{UserData.email}</span><br/>
                                </div>

                                <div className='panel-section'>
                                    <span><b>Статус подписки:</b> </span><span>{UserData.subscription.status}</span><br/>
                                    <span><b>Начало подписки:</b> </span><span>{UserData.subscription.startDate}</span><br/>
                                    <span><b>Окончание подписки:</b> </span><span>{UserData.subscription.endDate}</span><br/>
                                    <br/>
                                    <span><b>Первый платеж:</b> </span><span>{UserData.firstPayment} {UserData.currency}</span><br/>
                                    <span><b>Постоянная стоимость:</b> </span><span>{UserData.subscription.totalCost} {UserData.currency}</span><br/>
                                    <br/>
                                    <span><b>Скидка:</b> </span><span>{UserData.discount * 100}%</span><br/>
                                    <span><b>Первый const pay совершен:</b> </span><span>{UserData.firstPaymentIsActive ? 'Да' : 'Нет'}</span><br/>
                                    <br/>
                                    <span><b>Промо активировано:</b> </span><span>{UserData.isPromoActivated ? 'Да' : 'Нет'}</span><br/>
                                    <span><b>Количество устройств:</b> </span><span>{UserData.subscription.deviceCount}</span><br/>
                                </div>

                                <div className='panel-section'>
                                    <span><b>Код приглашения:</b> </span><span>{UserData.invitationCode}</span><br/>
                                    <span><b>Скидка:</b> </span><span>{UserData.referralDiscount}</span><br/>
                                </div>
                            </div>

                            <button className="panel-button" onClick={() => {navigate('/panel-receipts')}}>
                                Чеки
                            </button>

                            <button className="panel-button" onClick={() => {navigate('/panel-sales')}}>
                                Приглашенные пользователи
                            </button>

                            <button className="panel-button" onClick={() => {navigate('/panel-logs')}}>
                                Логи
                            </button>

                            <button className="panel-button">
                                Сделать возврат
                            </button>
                        </>
                    ) : (
                        <p>Loading data...</p>
                    )}

                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal'}}>
                    <p className='panel-block-title'>Устройства</p>
                    {UserData ? (
                        <>
                            {UserData.devices.map((device, index) => (
                                <div key={index} className='panel-section'>
                                    <div style={{ textAlign: 'left' }}>
                                        <span><b>ID:</b> </span><span>{device.id}</span><br/>
                                        <span><b>Другой ID:</b> </span><span>{device.deviceId}</span><br/>
                                        <span><b>Имя:</b> </span><span>{device.deviceName}</span><br/>
                                        <span><b>Платформа:</b> </span><span>{device.platform}</span><br/>
                                        <span><b>Активен:</b> </span><span>{device.isActive.toString()}</span><br/>
                                        <span><b>Создан:</b> </span><span>{(device.created)}</span><br/>
                                        <span><b>Дата активации:</b> </span><span>{(device.firstActivation)}</span><br/>
                                    </div>
                                    <button className="panel-button">Деактивация</button>
                                </div>
                            ))}
                        </>
                    ) : (
                        <p>Loading data...</p>
                    )}
                </div>

            </div>
        </div>
    );
}

export default PanelUser;

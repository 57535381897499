
import React, {useEffect, useState} from 'react';
import '../languages/i18n';
import {useNavigate} from "react-router-dom";
import './PanelStyle.css';
import {toast} from "react-toastify";


function PanelSales() {


    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <span><strong>Приглашенные пользователи</strong></span><br/>

                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal'}}>
                    {/*{UserData ? (*/}
                        <div>

                        </div>
                    {/*) : (*/}
                    {/*    <p>Loading data...</p>*/}
                    {/*)}*/}
                </div>

            </div>
        </div>
    );
}

export default PanelSales;

import './css/App.css';
import './css/Main.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from "./js/StartPage.js";
import Shop from "./js/Shop.js";
import Background from "./background/Background";
import YAuth from "./registration/YAuth";
import ShopVMobile from "./js/ShopVMobile";
import Profile from "./js/Profile";
import Promo from "./js/Promo";
import Dolor from "./js/Dolor";
import YAuthMobile from "./registration/YAuthMobile";
import Signature from "./js/elements/Signature";
import ProfileVMobile from "./js/ProfileVMobile";
import PanelLogin from "./panel/PanelLogin";
import SplashVoodoo from "./panel/SplashVoodoo";
import PanelSearch from "./panel/PanelSearch";
import PanelUser from "./panel/PanelUser";
import PanelReceipts from "./panel/PanelReceipts";
import PanelSales from "./panel/PanelSales";
import PanelLogs from "./panel/PanelLogs";
import './languages/i18n';
import PanelPromo from "./panel/PanelPromo";

function App() {
    return (
        <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{width: '100%'}}>
                <div>
                    <Background/>
                </div>
                <Router>
                    <Routes>
                        <Route path="/promo" element={<Promo/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/mobileShop" element={<ShopVMobile/>}/>
                        <Route path="/mobileProfile" element={<ProfileVMobile/>}/>
                        <Route path="/yauth" element={<YAuth/>}/>
                        <Route path="/yauth-mobile" element={<YAuthMobile/>}/>
                        <Route path="/dolor" element={<Dolor/>}/>

                        <Route path="/panel-login" element={<PanelLogin/>}/>
                        <Route path="/panel-voodoo" element={<SplashVoodoo/>}/>
                        <Route path="/panel-search" element={<PanelSearch/>}/>
                        <Route path="/panel-user" element={<PanelUser/>}/>
                        <Route path="/panel-receipts" element={<PanelReceipts/>}/>
                        <Route path="/panel-sales" element={<PanelSales/>}/>
                        <Route path="/panel-logs" element={<PanelLogs/>}/>
                        <Route path="/panel-promo" element={<PanelPromo/>}/>

                        <Route path="/shop" element={<Shop/>}/>
                        <Route path="" element={<StartPage/>}/>
                        <Route path="/" element={<StartPage/>}/>
                    </Routes>
                </Router>
            </div>
            <Signature/>
        </div>
    );
}

export default App;

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import '../languages/i18n';
import {useNavigate} from "react-router-dom";
import './PanelStyle.css';

function PanelSearch() {
    const navigate = useNavigate();
    const [SearchData, setSearchData] = useState(null);
    const [SearchString, setSearchString] = useState("");

    const [promoTypes, setPromoTypes] = useState([]);
    const [selectedPromoType, setSelectedPromoType] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(20);
    const [expirationMonths, setExpirationMonths] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        fetchSearchData();
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        axios.get('https://gostlink.com/api/panel/promos/promoTypes', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setPromoTypes(response.data);
                if (response.data.length > 0) {
                    setSelectedPromoType(response.data[0].type);
                }
                setIsLoaded(true);
            })
            .catch(error => {
                console.error('Error fetching promo types:', error);
                setIsLoaded(true); // Indicate loaded even if there is an error
            });
    }, []);

    const SubscriptionStatus = ({ subscriptionIsActive }) => {
        const spanStyle = {
            color: subscriptionIsActive ? 'green' : 'red'
        };

        return <span style={spanStyle}>Подписка</span>;
    };

    const fetchSearchData = () => {
        const accessToken = localStorage.getItem('accessToken');
        const url = `https://gostlink.com/api/panel/users/search?page=0&size=10&sortBy=id&emailPart=${SearchString}`;

        console.log(url);
        // console.log(`Bearer ${accessToken}`);

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                console.log(response.status);
                // console.log(response);
                console.log("JSON с челами типа");
                if (!response.ok) {
                    return response.text().then(text => {
                        if (response.status === 401) {
                            navigate('/panel-voodoo');
                        }
                        console.log(text);
                        throw new Error(`Request failed with status ${response.status}`);
                    });
                }
                return response.text();
            })
            .then(text => {
                // console.log(text);
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                setSearchData(data.content || []);
            })
            .catch(error => {
                console.log(error);
                console.error('Fetch error:', error);
            });
    };

    return (
        <div className="panel-container">
            <div className="panel-block">
                <span><strong>Поиск нужного профиля</strong></span><br/>
                <div style={{ display: 'flex', width: '100%' }}>
                    <input
                        type="text"
                        inputMode="text"
                        placeholder="Введите почту клиента..."
                        value={SearchString}
                        onChange={(e) => {
                            let newValue = e.target.value;
                            setSearchString(newValue);
                        }}
                        onKeyDown={() => fetchSearchData()}
                        style={{width: '100%'}}
                    />
                    <button className="panel-button"
                            onClick={() => fetchSearchData()}>Поиск
                    </button>
                </div>
            </div>

            <div className="panel-block">
                {SearchData ? (
                    SearchData.length > 0 ? (
                        <div>
                            {SearchData.map(user => (
                                <div className="panel-block-list" key={user.id}
                                     onClick={() => {
                                         localStorage.setItem('selected_person', user.id);
                                         navigate('/panel-user');
                                     }}>
                                    <span>{user.id} - {user.email} </span><br/>
                                    <SubscriptionStatus subscriptionIsActive={user.subscription.status} />
                                    { user.subscription.endDate ? (
                                        <span> - {user.subscription.endDate}</span>
                                    ) : (
                                        <span> - бессрочно</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <span className="text-tips" style={{fontSize: '12pt'}}>ничего не найдено</span>
                    )
                ) : (
                    <p>Loading data...</p>
                )}
            </div>

            <div className="panel-block">
                <button className="panel-button"
                        onClick={() => {navigate('/panel-promo');}}>
                    Промокоды, промокоды...
                </button>
            </div>

        </div>
    );
}

export default PanelSearch;

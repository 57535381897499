import React from 'react';
import { ReactComponent as Orbit1 } from "./orbit1.svg";
import { ReactComponent as Orbit2 } from "./orbit2.svg";
import { ReactComponent as Orbit3 } from "./orbit3.svg";
import { ReactComponent as Orbit4 } from "./orbit4.svg";

import orbit1 from "./orbit_1.svg";
import orbit2 from "./orbit_2.svg";
import orbit3 from "./orbit_3.svg";
import orbit4 from "./orbit_4.svg";
import './OrbitalAnimation.css'; // Import your CSS file

const OrbitalAnimation = () => {
    return (
        <>
            {/*<div className="primal_container">*/}
            {/*    /!*todo откалибровать для мобильной версии*!/*/}
            {/*    /!*todo перерисовать svg с меньшей толщиной линий*!/*/}
            {/*    /!*todo у пунктирной сделать планеты в 2 раза меньше*!/*/}
            {/*    <Orbit1 className="svg1" />*/}
            {/*    <Orbit2 className="svg2" />*/}
            {/*    <Orbit3 className="svg3" />*/}
            {/*    <Orbit4 className="svg4" />*/}
            {/*</div>*/}
            <div className="Orbits_container">
                <div className="Orbits">
                    <img src={orbit1}  className="orbit orbit-1"/>
                    <img src={orbit2}  className="orbit orbit-2"/>
                    <img src={orbit3}  className="orbit orbit-3"/>
                    <img src={orbit4}  className="orbit orbit-4"/>
                </div>
            </div>
        </>
    );
};


export default OrbitalAnimation;

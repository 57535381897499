import './Background.scss';
import OrbitalAnimation from "./OrbitalAnimation";
import LilStars from "./LilStars";
import BigStars from "./BigStars";
import StarStars from "./StarStars";
import {useEffect} from "react";

function BackgroundBlock() {

    return (
        <div id="Background">

            {/*<div id="stars"></div>*/}
            {/*<div id="stars2"></div>*/}
            {/*<div id="stars3"></div>*/}

            {/*<LilStars/>*/}
            {/*<BigStars/>*/}
            {/*TODO я изменил только StarStars, поправь там только длительности анимаций
                чтобы пакет звезд внутри имел длительность появления и исчезания равную времени существования пакета
                потом тоже самое для stars-2-3*/}

            <StarStars/>
            <OrbitalAnimation/>
        </div>
    );
}

export default BackgroundBlock;
import React, { useEffect, useState } from 'react';
import '../languages/i18n';
import { useNavigate } from "react-router-dom";
import './PanelStyle.css';
import { toast } from "react-toastify";

function PanelReceipts() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchReceipts = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const userId = localStorage.getItem('selected_person');
                const url = `https://gostlink.com/api/panel/checks?userId=${userId}&page=0&size=100`;

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (!response.ok) throw new Error("Ошибка сети");

                const data = await response.json();
                setUserData(data.content);
            } catch (error) {
                console.error("Ошибка загрузки данных:", error);
                toast.error("Ошибка загрузки данных");
            }
        };

        fetchReceipts();
    }, []);

    return (
        <div className="panel-main">
            <div className="panel-container">
                <div className="panel-block">
                    <span><strong>Чеки об оплате</strong></span><br />
                </div>

                <div className="panel-block" style={{ justifyContent: 'space-around', alignItems: 'normal' }}>
                    {userData ? (
                        userData.map((receipt, index) => (
                            <div key={index} className="panel-section">
                                <p><strong>Дата:</strong> {new Date(receipt.dateTime).toLocaleString()}</p>
                                <p><strong>Email:</strong> {receipt.accountId}</p>
                                <p><strong>Сумма:</strong> ${receipt.amount}</p>
                                <p><strong>Описание:</strong> {receipt.description}</p>
                                <p><strong>IP адрес:</strong> {receipt.ipAddress}</p>
                                <p><strong>Статус:</strong> {receipt.status}</p>
                                <p><strong>ID транзакции:</strong> {receipt.transactionId}</p>
                                <p><strong>ID подписки:</strong> {receipt.subscriptionId}</p>
                                <p><strong>Гарантия:</strong> {receipt.isWarrantyOver}</p>
                            </div>
                        ))
                    ) : (
                        <p>Загрузка данных...</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PanelReceipts;

import React, {useEffect, useState} from 'react';
import '../../css/Signature.css';
import '../../languages/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { FaTelegramPlane } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import {addGoogleAnalytics} from "../../registration/analytics";



function Signature() {

    const { i18n, t } = useTranslation();
    const defaultLanguage = (i18n.language || navigator.language).split('-')[0];
    const [activeLanguage, setActiveLanguage] = useState(defaultLanguage);

    const changeLanguage = (language) => {
        setActiveLanguage(language);
        i18n.changeLanguage(language);
    };


    const handleTelegramClick = () => {
        const formatEmail = (email) => btoa(email);
        const email = localStorage.getItem('email');
        const encodedEmail = email ? formatEmail(email) : '';
        window.open('https://t.me/gostlink_support_bot?start=' + encodedEmail, '_blank');
    };

    return (
        <div className="signature">
            <hr className='full-line'/>
            <div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
                <div className="support" onClick={handleTelegramClick}>
                    <span>{t('Signature.Support')}</span>
                    <FaTelegramPlane style={{color: 'white'}}/>
                </div>
                <div className="support" onClick={() => window.location.href = 'mailto:info@gostlink.ru'}>
                    <span>{t('Signature.Email')}</span>
                    <IoIosMail style={{color: 'white'}}/>
                </div>
            </div>
            <div className="languages-change">
                <button onClick={() => changeLanguage('ru')} className={activeLanguage === 'ru' ? 'active' : ''}>
                    {t('Signature.LanguageOptions.Russian')}
                </button>
                <button onClick={() => changeLanguage('en')} className={activeLanguage === 'en' ? 'active' : ''}>
                    {t('Signature.LanguageOptions.English')}
                </button>
                <button onClick={() => changeLanguage('kk')} className={activeLanguage === 'kk' ? 'active' : ''}>
                    {t('Signature.LanguageOptions.Kazakh')}
                </button>
                <button onClick={() => changeLanguage('zh')} className={activeLanguage === 'zh' ? 'active' : ''}>
                    {t('Signature.LanguageOptions.Chinese')}
                </button>
            </div>
            <div className="company-info">
                <p>{t('Signature.CompanyInfo')}</p>
                <p>{t('Signature.INN')}</p>
            </div>

            <div className="company-files" onClick={() => {addGoogleAnalytics('afferta', 'main', 'click to read'); window.open('https://gostlink.com/get/docs/oferta_kannt.pdf', '_blank');}}>
                <span >{t('Signature.PublicOffer')}</span>
            </div>
        </div>
    );
}

export default Signature;

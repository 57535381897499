
import React, {useEffect, useState} from 'react';
import '../languages/i18n';
import {useNavigate} from "react-router-dom";
import './PanelStyle.css'
import {FaEye, FaEyeSlash} from "react-icons/fa";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";


function PanelLogin() {
    const navigate = useNavigate();
    const [Login, setLogin] = useState("");
    const [Password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ErrorMsg, setErrorMsg] = useState();

    const [showBlock, setShowBlock] = useState(false);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [captchaCalled, setCaptchaCalled] = useState(false);

    const reCaptchaSolved = () => {
        setCaptchaCalled(true);
        setShowBlock(true);
    };

    const fetchLoginData = () => {

        if (showBlock) {

            setIsLoading(true);
            const requestData = {
                username: Login,
                password: Password,
            };

            fetch(`https://gostlink.com/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(response => {
                    if (!response.ok) {
                        return response.text().then(text => {
                            setErrorMsg(text);
                            setIsLoading(false);
                            throw new Error(text);
                        });
                    }
                    return response.text();
                })
                .then(text => {
                    if (text) {
                        return JSON.parse(text);
                    }
                    return {};
                })
                .then(data => {
                    setErrorMsg("");
                    localStorage.setItem('accessToken', data.accessToken);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    navigate('/panel-search');
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    // setErrorMsg(error);
                    setIsLoading(false);
                });

        } else {
            setErrorMsg('reCAPTCHA not solved!');
        }
    };




return (
    <div className="panel-main">
        <div className="panel-container">
            {!captchaCalled && (
                <GoogleReCaptchaProvider reCaptchaKey="6LdY3WkqAAAAAIToOD7e2MMkB7etmffWQh4RJpMh">
                    <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        onVerify={reCaptchaSolved}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                </GoogleReCaptchaProvider>
            )}

            <div className="panel-block">
                <span><b>Только для внутреннего использования</b></span>
            </div>
            <div className="panel-block">
                <table>
                    <tr>
                        <td><span>Логин:</span></td>
                        <td>
                            <input type="text"
                                   style={{width: '100%', margin: '0'}}
                                   value={Login}
                                   onChange={(e) => {
                                    let newValue = e.target.value;
                                    setLogin(newValue);}}
                                   inputMode="text"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><span>Пароль:</span></td>
                        <td>
                            <input type={showPassword ? "text" : "password"}
                                   style={{width: '100%', margin: '0'}}
                                   value={Password}
                                   onChange={(e) => {
                                       let newValue = e.target.value;
                                       setPassword(newValue);
                                   }}
                                   inputMode="text"
                            />
                        </td>
                        <td>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {showPassword ?
                                    <FaEyeSlash
                                        style={{color: 'white', width: '20px'}}
                                        onClick={() => setShowPassword(!showPassword)}
                                    /> :
                                    <FaEye
                                        style={{color: 'white',  width: '20px'}}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                }
                            </div>
                        </td>
                    </tr>
                </table>
                <button className="panel-button"
                        onClick={() => fetchLoginData()}
                        disabled={isLoading}>
                    {isLoading ? 'Загрузка...' : 'Войти'}
                </button>
                { ErrorMsg ? (<><br/><span style={{color: 'red'}}>{ErrorMsg}</span></>) : (<></>)

                }
            </div>
        </div>
    </div>
);
}

export default PanelLogin;
